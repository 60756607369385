html body {
    background-color: white;
    font-weight: 300;
}

body main {
    font-size: 17px;
}

footer {
    opacity: 0.87;
    font-size: 100%;
    position: fixed;
    bottom: 0;
    padding: 0.25em;
    border-top-right-radius: 2px;
    background-color: hsla(0, 0%, 95%, 0.9);
}

main div.section, main section {
    height: 700px;
    margin-bottom: 6em;
}

nav#main-nav {
    z-index: 999;
    background: none;
    border: none;
    box-shadow: none;
}
    #main-nav ul li {
        width: 9rem;
    }

    #main-nav ul a {
        font-size: 1.2rem;
        text-align: center;
    }

    #main-nav ul a:hover {
        background-color: hsl(0, 0%, 88%);
    }

small {
    opacity: 0.5;
}
@media only screen and (max-width: 992px) {
    div.navbar-fixed {
        height: 0.5rem;
    }

    html div#savant {
        top: 1%;
    }
}

#main-nav ul.top-nav, .sidenav {
    width: 100%;
    background-color: hsl(0, 0%, 93%);
    padding: 0.1em;
}

    a#do-it {
        position: relative;
        float: right;
        right: 0.5em;
        top: 0.75rem;
        text-shadow: 2px 2px 0 rgba(10, 10, 10, 0.1);
        opacity: 0.65;
        margin: 0em -0.5em 0 0em;
        cursor: pointer;
    }
    a#do-it i {
        font-size: 3.25em;
    }

nav .sidenav {
    width: 12em;
    text-align: right;
}
    #main-nav  .sidenav li a {
        font-weight: normal;
        font-size: 1.4em;
        padding: 1.5rem 0;
        height: auto;
        width: 12rem;
    }
.sidenav-overlay {
    z-index: 100;
}

html nav a.button-collapse i {
    font-size: 2.5rem;
    opacity: 0.5;
}

main h2, main h3, main h4, main h5 {
    font-weight: 300;
}

body strong {
    font-weight: 600;
}

body a, body a:visited, #main-nav ul a, nav i {
    color: hsl(188, 75%, 35%);
}

main .icon {
    display: block;
    color: hsl(7, 75%, 29%);
    font-size: 4.25em;
    text-align: center;
    margin-top: 0.35em;
    margin-bottom: 0.1em;
    height: 1.25em;
}
main .icon i {
    display: block;
}

main i.icon-elm {
    font-size: 80%;
}

main i.icon-html5-alt {
    font-size: 92.5%;
}


main i.icon-haskell {
    font-size: 115%;
}
main i.icon-aws{

    padding-top: 0.1em;
}


a i {
    opacity: 0.85;
}

main h1, main h2, main h3, main h4, main h5 {
    color: hsl(7, 75%, 29%);
}

main h5 {
    margin-top: 1em;
    margin-bottom: 0.1em;
}

main h1, #gym-slogan-box, #hipster-slogan-box {
    font-family: Impact, 'Passion One', 'Arial Black', sans-serif;
}

main h1 {
    font-size: 5.5em;
    margin-bottom: 0.25em;
    line-height: 0.9em;
}

main h3 {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

main blockquote {
    font-family: monospace;
    font-size: 0.9em;
    max-width: 50em;
    margin-bottom: 0.25em;
    border-left-color: #b2dfdb;
}

main pre .nocode {
    color: white;
}


div#mcBain, div#savant {
    opacity: 0;
    position: fixed;
    right: 0;
    padding:0;
    margin-right:0;
    text-align:right;
    z-index: 10;
}
div#mcBain {
    bottom: 0;
    width: 30%;
    max-width: 300px;
    max-height: 300px;
    min-height: 160px;
    min-width: 160px;
    transition: opacity ease-in 0.2s;
}

div#savant {
    top: 9%;
    right: 2%;
    z-index: 100;
    width: 25%;
    max-width: 220px;
    max-height: 220px;
    min-height: 110px;
    min-width: 110px;
    transition: opacity ease-in-out 0.85s;
}
    #mcBain div, #savant div {
        float: right;
        height: auto;
        width: 100%;
        background-size: contain;
    }
    #mcBain div {
        background-image: url("../../static/images/mcbain-gun.png");
        height: 300px;
        width: 300px;
    }
    #savant div {
        height: 180px;
        width: 184px;
        background-image: url("../../static/images/hipster-sign-cutout.png");
    }

#gym-slogan-box, #hipster-slogan-box {
    color: hsl(8, 58%, 33%);
    font-size: 2.4em;
    line-height:0.95em;
    min-height: 1.5em;
    text-transform:uppercase;
    border-radius: 2px;
    padding: 0.25em;
    margin: 0.5em 0 0.5em 0;
    background: #E8E8E8;
}

    #gym-slogan-box strong, #hipster-slogan-box strong {
        font-weight: inherit;
    }


/* Audio stuff */
iframe.hidden-iframe {
    width: 0;
    height: 0;
    border: none;
    position:absolute;
}

/* Fullpage stuff */

#fp-nav ul li, .fp-slidesNav ul li {
    margin: 18px 7px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    opacity: 0.3;
    height: 8px;
    width: 8px;
    margin: -4px 0px 0px -4px;
    background: #EEE;
    border: 1px solid #666;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
    opacity: 0.6;
    height: 12px;
    width: 12px;
    margin: -6px 0px 0px -6px;
}

#fp-nav ul li a.active span,
        .fp-slidesNav ul li a.active span,
        #fp-nav ul li:hover a.active span,
        .fp-slidesNav ul li:hover a.active span {
    background-color: #DDD;
    opacity: 0.6;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 -7px;
}



/* desert scheme ported from vim to google prettify */
pre { display: block; background-color: #333; color: white; border-radius: 0.2rem;}
pre .str { color: #ffa0a0 } /* string  - pink */
pre .kwd { color: #f0e68c; font-weight: bold }
pre .com { color: #87ceeb } /* comment - skyblue */
pre .typ { color: #98fb98 } /* type    - lightgreen */
pre .lit { color: #cd5c5c } /* literal - darkred */
pre .pun { color: #fff }    /* punctuation */
pre .pln { color: #fff }    /* plaintext */
pre .tag { color: #f0e68c; font-weight: bold } /* html/xml tag    - lightyellow */
pre .atn { color: #bdb76b; font-weight: bold } /* attribute name  - khaki */
pre .atv { color: #ffa0a0 } /* attribute value - pink */
pre .dec { color: #98fb98 } /* decimal         - lightgreen */

/* Specify class=linenums on a pre to get line numbering */
ol.linenums { margin-top: 0; margin-bottom: 0; color: #AEAEAE } /* IE indents via margin-left */
li.L0,li.L1,li.L2,li.L3,li.L5,li.L6,li.L7,li.L8 { list-style-type: none }
/* Alternate shading for lines */
li.L1,li.L3,li.L5,li.L7,li.L9 { }
